<template>
  <section id="dashboard-home">
    <b-row class="match-height">
      <b-col
        lg="6"
        md="12"
      >
        <analytics-timeline :data="mlfAnalytics.timeline" />
      </b-col>
      <b-col
        lg="6"
        md="12"
      >
        <b-row class="match-height">
          <b-col
            lg="6"
            md="12"
          >
            <statistic-card-with-area-chart
              v-if="mlfAnalytics.newCreatorsData"
              icon="LayersIcon"
              :statistic="kFormatter(mlfAnalytics.newCreatorsData.analyticsCount.subscribers)"
              statistic-title="Creator Applications"
              :chart-data="mlfAnalytics.newCreatorsData.series"
            />
          </b-col>
          <b-col
            lg="6"
            md="12"
          >
            <statistic-card-with-area-chart
              v-if="mlfAnalytics.newUserData"
              icon="UserPlusIcon"
              color="warning"
              :statistic="kFormatter(mlfAnalytics.newUserData.analyticsCount.orders)"
              statistic-title="New Users"
              :chart-data="mlfAnalytics.newUserData.series"
            />
          </b-col>
        </b-row>
        <b-row class="match-height">
          <b-col
            lg="6"
            md="12"
          >
            <statistic-card-with-area-chart
              v-if="mlfAnalytics.subscriberData"
              icon="UsersIcon"
              :statistic="kFormatter(mlfAnalytics.subscriberData.analyticsCount.subscribers)"
              statistic-title="Subscribers"
              :chart-data="mlfAnalytics.subscriberData.series"
            />
          </b-col>
          <b-col
            lg="6"
            md="12"
          >
            <statistic-card-with-area-chart
              v-if="mlfAnalytics.orderData"
              icon="DollarSignIcon"
              color="warning"
              :statistic="'$' + kFormatter(mlfAnalytics.orderData.analyticsCount.orders)"
              statistic-title="Profit"
              :chart-data="mlfAnalytics.orderData.series"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="6">
        <analytics-sales-radar-chart :data="mlfAnalytics.salesChart" />
      </b-col>
      <b-col lg="6">
        <analytics-support-tracker :data="mlfAnalytics.supportTracker" />
      </b-col>
    </b-row>

    <!-- <b-row class="match-height">
      <b-col
        lg="12"
        md="12"
        cols="12"
      >
        <mlf-user-list />
      </b-col>
    </b-row> -->
  </section>
</template>

<script>
import api from '@mlfcore/api'
import { BRow, BCol } from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
// import MLFUserList from '@mlfcore/components/user-list.vue'
import StatisticCardWithAreaChart from '@mlfcore/components/dashboards/StatisticCardWithAreaChart.vue'
import AnalyticsSupportTracker from '@mlfcore/components/dashboards/HomeSupportTracker.vue'
import AnalyticsTimeline from '@mlfcore/components/dashboards/HomeTimeline.vue'
import AnalyticsSalesRadarChart from '@mlfcore/components/dashboards/HomeSalesRadarChart.vue'

export default {
  components: {
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    AnalyticsSupportTracker,
    AnalyticsTimeline,
    AnalyticsSalesRadarChart,
    // 'mlf-user-list': MLFUserList,
  },
  data() {
    return {
      data: {},
      mlfAnalytics: {
        timeline: {},
        tempTimeline: {
          step1: {
            duration: '0 min ago',
            fileName: 'data.json',
            img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAkCAMAAAAw96PuAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAIaADAAQAAAABAAAAJAAAAADeoA9wAAABcVBMVEUAAAD/qlX/qlX/n2D/qlX/n1D/pUv/qkf/oUP/pk3/qkn/okb/o0f/okT/n0j/oEn/n0b/pEn/oEf/okb/oUj/o0f/n0X/oUT/o0f/oEb/pEP/okb/n0T/okT/oEb/n0T/oEX/o0b/n0P/oUP/okb/oUT/n0b/oUX/oET/oUb/n0X/oET/oUP/oEX/okT/oEP/oUX/oUP/oET/oUX/oEX/n0T/oEX/oET/oEX/n0T/oET/n0P/oEX/n0P/oUT/n0T/oEX/oUT/oET/oEP/oET/oUT/oEP/oEP/oET/oEP/oEP/oET/n0T/oET/oEP/n0T/oET/n0T/n0T/oET/n0T/n0P/oET/n0P/oET/n0T/oEP/oET/oET/n0T/n0T/oEP/oEP/n0P/oET/n0P/n0T/n0T/n0P/oEP/n0T/oEP/n0P/oET/n0T/oEP/oET/n0T/oET/oEP/n0T/oEP/oET/n0P/oEP/oET/n0T/oEP/n0NWaDR5AAAAenRSTlMAAwYICRAREhMUFRYZHiAjKCorLC4vMDEyMzU3ODw+QENFSExNT1BRU1RVVldZWltcX2Zna21zdHZ4e31+hYeIkZKTlJmam5yen6OkpaanqKmtsLG4ury9v8DBw8fIytHU1dfY2t3g5OXn6Onq6+zt7u/w8/n6+/z9/jLTlDYAAAE3SURBVDjL7dRHUwIxFMDxZ0OsIAoqtijqYhcVCxZQZO0iWLAXYC0IsqIivE/vRh2GLLs5evJ/yLyZ/CaHzCQAAAbxAZmezMBUfoPqEk2McGFpz5Zi4VPtyjllSTYXiYBKRCYpSbVwBIx/UmLlCBij5MXGETCaVYZ0G0fAMCVyO0eA80MZX+0cAQIlmVaOgAFK5rREbOq3sJ5g+hcaQvweTyOYCPjjiMf+c2XZyeP+PSsER6rW2ruNQzWDlW4ksIlVB2pxBnuIR3CCqxAjlvo3Y4nITgCRlsve8RZCxGP2NgRZ0S8gXpuc6xDFEFyRhWBdI3NGdM2wKC1tmEbSxr5dmz1H5vMdwIhw13TmTuh2JzHu6pmV0bOFl46Lv71TH0/M6P0OhTp1fphChz8Po1p81N6XVioAvgBZgp3AxW+3KgAAAABJRU5ErkJggg==',
            subtitle: 'Test Post Subtitle',
            title: 'Test Post',
          },
          step2: {
            title: 'Client Meeting',
            subtitle: 'Project meeting with john @10:15am',
            avatar: '/img/avatar-s-9.397f0acd.jpg',
            avatarName: 'John Doe (Client)',
            occupation: 'CEO of Infibeam',
            duration: '45 min ago',
          },
        },
        supportTracker: {
          title: 'Support Tracker',
          lastDays: [
            'Last 28 Days',
            'Last Month',
            'Last Year',
          ],
          totalTicket: 0,
          newTicket: 0,
          openTicket: 0,
          responseTime: 0,
          supportTrackerRadialBar: {
            series: [
              0,
            ],
          },
        },
        salesChart: {},
        tempSalesChart: {
          series: [
            {
              name: 'Sales',
              data: [
                0,
              ],
            },
            {
              name: 'Visit',
              data: [
                0,
              ],
            },
          ],
        },
        newCreatorsData: {
          series: [
            {
              name: 'Creator Applications',
              data: [
                5, 5, 5, 5,
              ],
            },
          ],
          analyticsCount: {
            subscribers: 0,
          },
        },
        newUserData: {
          series: [
            {
              name: 'New Users',
              data: [
                5, 5, 5, 5,
              ],
            },
          ],
          analyticsCount: {
            orders: 0,
          },
        },
        subscriberData: {
          series: [
            {
              name: 'Subscribers',
              data: [
                5, 5, 5, 5,
              ],
            },
          ],
          analyticsCount: {
            subscribers: 0,
          },
        },
        orderData: {
          series: [
            {
              name: 'Profit',
              data: [
                5, 5, 5, 5,
              ],
            },
          ],
          analyticsCount: {
            orders: 0,
          },
        },
      },
    }
  },
  created() {
    // data
    this.$http.get('/analytics/data')
      .then(response => {
        this.data = response.data
      })
  },
  mounted() {
    this.initalize()
  },
  methods: {
    kFormatter,
    initalize() {
      this.getData()
    },
    getData() {
      api.getDashboard('default', {
        count: 10,
        page: 1,
      }).then(response => {
        /* eslint-disable no-console */
        this.mlfAnalytics.newCreatorsData.analyticsCount.subscribers = response.creators.new
        this.mlfAnalytics.newUserData.analyticsCount.orders = response.users.new
        this.mlfAnalytics.subscriberData.analyticsCount.subscribers = response.subscribers.new
        this.mlfAnalytics.orderData.analyticsCount.orders = response.profits.total
        console.log('API Dashboard Data Loaded')
        console.log(response)
      }).catch(error => {
        /* eslint-disable no-console */
        console.log(error)
      }).then(() => {
        console.log('Temp Analytics Data Loaded')
        console.log(this.data)
      })
    },
  },
}
</script>
